/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import rootState from '../../store'
// console.log('navigation:rootState', rootState)
const navigation = rootState.state.navigation.navigation
export default  navigation

// Array of sections
// export default [
//     {
//       title: 'Reception',
//       route: 'reception',
//       icon: 'HomeIcon',
//     },
//     {
//       title: 'Stage',
//       route: 'stage',
//       icon: 'VideoIcon',
//     },
//     {
//       title: 'Sessions',
//       route: 'sessions',
//       icon: 'CalendarIcon',
//     },
//     {
//       title: 'Speakers',
//       route: 'speakers',
//       icon: 'UsersIcon',
//     },
//     {
//       title: 'Sponsors & Organizers',
//       route: 'exhibition',
//       icon: 'GridIcon',
//     },
//     {
//       title: 'Partners',
//       route: 'partners',
//       icon: 'ApertureIcon',
//     },
//     {
//       title: 'Attendees',
//       route: 'attendees',
//       icon: 'UsersIcon',
//     },
//     {
//       title: 'Contests',
//       route: 'contests',
//       icon: 'AwardIcon',
//     },
//     // {
//     //   title: 'Networking',
//     //   route: 'apps-chat',
//     //   icon: 'MessageCircleIcon',
//     // },
//   ]
